import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

const Menu = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  const currentPath = props.path;
  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {menuLinks.map((link) => (
          <li
            key={link.name}
            className={currentPath === link.link ? "active" : ""}
          >
            <Link to={link.link}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={(data) => <Menu data={data} path={props.path} />}
  />
);
