import React, { useState } from 'react';

const Hamburger = (props) => {
    const { toggleMenu } = props;
    const [hamburgerActive, setHamburgerActive] = useState(false);

    const handleToggle = () => {
    setHamburgerActive(!hamburgerActive);
    toggleMenu(hamburgerActive);
    };

    return (
        <button
        id="toggle-main-menu-mobile"
        className={`hamburger hamburger--slider ${
            hamburgerActive ? 'is-active' : ''
        }`}
        type="button"
        onClick={handleToggle}
        >
        <span className="hamburger-box">
            <span className="hamburger-inner" />
        </span>
        </button>
    );
}

export default Hamburger;
