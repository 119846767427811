import React from "react";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../scss/style.scss";

const Layout = (props) => {
  return (
    <>
      <SEO />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ""}`}>
        <div id="wrapper" className="wrapper">
          <Header path={props.path} />
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
