import React from "react";
import { graphql, StaticQuery } from "gatsby";

const Footer = (props) => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer">            
            <span className="copyright">
              © 2017 - {new Date().getFullYear()}{" "}
              {props.data.site.siteMetadata.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
