import React, { useState } from "react";
import { Link } from "gatsby";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import logoMobile from "../images/logo-mobile.svg";
import MenuMobile from "./MenuMobile";

const Header = (props) => {
    const { path } = props;
    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    }

    return (
        <div className="header sticky">
        <div className="container">
            <div className="logo">
            <Link to="/">
                <img alt="Homepage" src={logoMobile} />
            </Link>
            </div>
            <div className="logo-mobile">
            <Link to="/">
                <img alt="Homepage" src={logoMobile} />
            </Link>
            </div>
            <MenuMobile active={menuActive} />
            <Menu path={path} />
            <Hamburger toggleMenu={toggleMenu} />
        </div>
        </div>
    );
}

export default Header;
